<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            xl="3"
          >
            <b-row class="d-flex flex-nowrap pl-1">
              <div class="w-100 d-flex justify-content-start">
                <div>
                  <b-form-checkbox
                      @change="selectAllEmployers()"
                  />
                </div>
                <label>Owner</label>
              </div>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                xl="12"
              >
<!--                {{ selectedEmployer }}-->
<!--                <multiselect-->
<!--                    v-model="selectedEmployer"-->
<!--                    :options="employersOptions"-->
<!--                    :multiple="true"-->
<!--                    :limit="3"-->
<!--                    label="name"-->
<!--                    track-by="id"-->
<!--                    @select="getReport"-->
<!--                ></multiselect>-->
                <v-select
                  id="employers-list"
                  v-model="selectedEmployer"
                  dir="ltr"
                  multiple
                  label="name"
                  :reduce="employer => employer.id"
                  :options="employersOptions"
                  @input="getReport"
                >
                </v-select>
              </b-col>
            </b-row>
          </b-col>
          <b-col
              cols="12"
              xl="3"
          >
            <b-row class="d-flex flex-nowrap pl-1">
              <div class="w-100 d-flex justify-content-start">
                <div>
                  <b-form-checkbox
                      @change="selectAllBusinessType()"
                  />
                </div>
                <label>Business Type</label>
              </div>
            </b-row>
            <b-row>
              <b-col
                  cols="12"
                  xl="12"
              >
                <v-select
                    v-model="businessType"
                    single-line
                    dir="ltr"
                    multiple
                    label="name"
                    :reduce="type => type.value"
                    :options="businessTypes"
                    @input="getReport"
                />
              </b-col>
            </b-row>
          </b-col>
<!--          <b-col-->
<!--            cols="12"-->
<!--            xl="3"-->
<!--          >-->
<!--            <label>Business Type</label>-->
<!--            <v-select-->
<!--              v-model="businessType"-->
<!--              single-line-->
<!--              dir="ltr"-->
<!--              multiple-->
<!--              label="name"-->
<!--              :reduce="type => type.value"-->
<!--              :options="businessTypes"-->
<!--              @input="getReport"-->
<!--            />-->
<!--          </b-col>-->
          <b-col
            cols="12"
            xl="6"
          >
            <label>Close Date</label>
            <VueCtkDateTimePicker
              v-model="dateModel"
              format="YYYY-MM-DD"
              formatted="ll"
              range
              label="Select Date"
              locale="en"
              auto-close
              :custom-shortcuts="myRanges"
              :shortcut="'thisMonth'"
              @input="getReport()"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <metrics-component
      :total-premium="totalPremium"
      :op-sold="opportunitySold"
      :op-quoted="opportunityQuoted"
      :base-premium="basePremium"
      :loading="loading"
    />
    <b-card no-body>
      <b-card-header>
        <b-card-title class="mb-sm-0 mb-1">
          Performance to Goal
        </b-card-title>
      </b-card-header>
      <b-row>
        <b-card-body>
          <b-row class="d-flex justify-content-center">
            <vue-apex-charts
              ref="opSoldChart"
              :key="opportunitySoldChart"
              type="radialBar"
              height="400"
              :options="chartOptionsOpportunitySold"
              :series="chartSeriesOportunitySold"
            />
            <vue-apex-charts
              ref="totalPremChart"
              :key="totalPremiumChart"
              type="radialBar"
              height="400"
              :options="chartOptionsTotalPremium"
              :series="chartSeriesTotalPremium"
            />
            <vue-apex-charts
              ref="commissionChart"
              :key="commissionChart"
              type="radialBar"
              height="400"
              :options="chartOptionsCommission"
              :series="chartSeriesCommission"
            />
          </b-row>
        </b-card-body>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12">
        <trends-component
          :key="trendsComponent"
          :trends="this.trends"
          :show-op-sold="true"
          :show-stacked-chart="true"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BLink, BRow, BButton, BCardBody, BCardTitle, BCardHeader, BCol, BSkeleton, BSkeletonWrapper,
  BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { decryptUserData } from '@/_helpers/encryption'
import TrendsComponent from '@/views/dashboard/TrendsComponent'
import { analysError } from '@/_helpers/analys_error'
import { number_to_money } from '@/_helpers/number_to_money'
import { Role } from '@/_helpers/role'
import Multiselect from "vue-multiselect";
import MetricsComponent from "@/views/dashboard/MetricsComponent";
import {policy_is_sold} from "@/_helpers/check_policy_is_sold";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BButton,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCol,
    VueApexCharts,
    vSelect,
    TrendsComponent,
    BSkeleton,
    BSkeletonWrapper,
    BFormCheckbox,
    Multiselect,
    MetricsComponent,
  },
  data() {
    return {
      selectedEmployer: decryptUserData(useJwt.getUserData()).role_id !== Role.Admin
        ? decryptUserData(useJwt.getUserData()).id : [],
      businessType: [],
      dateModel: {},
      employersOptions: [],
      businessTypes: [
        {
          value: 'N',
          name: 'New Business',
        },
        {
          value: 'R',
          name: 'Renewal',
        },
        {
          value: 'W',
          name: 'Rewrite',
        },
      ],
      totalPremiumChart: 0,
      opportunitySoldChart: 1,
      trendsComponent: 2,
      commissionChart: 3,
      chartOptionsOpportunitySold: {
        colors: ['#20E647'],
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            size: 185,
            hollow: {
              size: '35%',
            },
            track: {
              startAngle: -90,
              endAngle: 90,
              margin: 15,
            },
            dataLabels: {
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
                offsetY: 100,
              },
              value: {
                fontSize: '30px',
                fontFamily: 'Montserrat',
              },
              total: {
                show: false,
                fontSize: '1rem',
                formatter() {
                  return this.countPoliciesGoal
                },
              },
            },
          },
        },
        stroke: {
          lineCap: 'butt',
        },
        labels: ['Opportunities Sold'],
        subtitle: {
          align: 'center',
          offsetX: 0,
          offsetY: 255,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Montserrat',
            color: '#3BCD10',
          },
        },
      },
      chartOptionsTotalPremium: {
        colors: ['#3BCD10'],
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            size: 185,
            hollow: {
              size: '35%',
            },
            track: {
              margin: 15,
              startAngle: -90,
              endAngle: 90,
            },
            dataLabels: {
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
                offsetY: 100,
              },
              value: {
                fontSize: '30px',
                fontFamily: 'Montserrat',
              },
              total: {
                show: false,
                fontSize: '1rem',
                formatter() {
                  return '100%'
                },
              },
            },
          },
        },

        stroke: {
          lineCap: 'butt',
        },
        labels: ['Total Premium'],
        subtitle: {
          align: 'center',
          offsetX: 0,
          offsetY: 255,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Montserrat',
            color: '#3BCD10',
          },
        },
      },
      chartOptionsCommission: {
        colors: ['#20E647'],
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            size: 185,
            hollow: {
              size: '35%',
            },
            track: {
              startAngle: -90,
              endAngle: 90,
              margin: 15,
            },
            dataLabels: {
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
                offsetY: 100,
              },
              value: {
                fontSize: '30px',
                fontFamily: 'Montserrat',
              },
              total: {
                show: false,
                fontSize: '1rem',
                formatter() {
                  return '100%'
                },
              },
            },
          },
        },
        stroke: {
          lineCap: 'butt',
        },
        labels: ['Commission'],
        subtitle: {
          align: 'center',
          offsetX: 0,
          offsetY: 255,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: 'normal',
            fontFamily: 'Montserrat',
            color: '#3BCD10',
          },
        },
      },
      chartSeriesOportunitySold: [0],
      chartSeriesTotalPremium: [0],
      chartSeriesCommission: [0],
      myRanges: [
        { key: 'thisWeek', label: 'This week', value: 'isoWeek' },
        { key: 'lastWeek', label: 'Last week', value: '-isoWeek' },
        { key: 'thisMonth', label: 'This month', value: 'month' },
        { key: 'lastMonth', label: 'Last month', value: '-month' },
        {
          key: 'ytd',
          label: 'YTD',
          value: () => ({
            start: this.$moment().startOf('year'),
            end: this.$moment(),
          }),
        },
        {
          key: 'mtd',
          label: 'MTD',
          value: () => ({
            start: this.$moment().startOf('month'),
            end: this.$moment(),
          }),
        },
      ],
      loading: false,
      policies: [],
      countPoliciesGoal: 0,
      totalPremiumPoliciesGoal: 0,
      commissionPoliciesGoal: 0,
      totalPremium: 0,
      basePremium: 0,
      totalPremiumPercent: 0,
      opportunitySold: 0,
      opportunitySoldPercent: 0,
      commission: 0,
      trends: {},
      isAllEmployers: false,
      soldPolicies: [],
      quotedPolicies: [],
      opportunityQuoted: 0,
    }
  },
  beforeMount() {
    this.getEmployers()
  },
  methods: {
    selectAllBusinessType() {
      if (event.target.checked) {
        this.businessTypes.map(type => {
          if (!this.businessType.includes(type.value)) {
            this.businessType.push(type.value)
          }
        })
        this.getReport()
        return
      }
      this.businessType = []
      this.getReport()
    },
    selectAllEmployers() {
      if (event.target.checked) {
        this.isAllEmployers = true
        this.employersOptions.map(employer => {
          if (!this.selectedEmployer.includes(employer.id)) {
            this.selectedEmployer.push(employer.id)
          }
        })
        this.getReport()
        return
      }
      this.isAllEmployers = false
      this.selectedEmployer = []
      this.getReport()
    },
    getReport() {
      if (this.dateModel === null
          || this.dateModel.start === null
          || this.dateModel.end === null) return
      this.loading = true
      axios.get(`${process.env.VUE_APP_API_URL}/dashboard?from=${this.dateModel.start}&to=${this.dateModel.end}`
          + `&employers=${this.selectedEmployer}&business_type=${this.businessType}&trends`, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.loading = false
        this.policies = response.data.data.policies
        this.countPoliciesGoal = response.data.data.countPoliciesGoal
        this.totalPremiumPoliciesGoal = response.data.data.totalPremiumPoliciesGoal
        this.commissionPoliciesGoal = response.data.data.commissionPoliciesGoal
        this.trends = response.data.data.trends
        this.calculateChartSeries()
      }).catch(error => {
        console.log(error)
        this.loading = false
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    },
    calculateChartSeries() {
      this.totalPremium = 0
      this.opportunitySold = 0
      this.opportunityQuoted = 0
      this.commission = 0
      this.basePremium = 0
      // this.soldPolicies = this.policies.filter(policy => (policy.agent_status === 'A' && policy.state === 'S'
      //     && policy.status !== 'P'))
      this.soldPolicies = this.policies.filter(policy => policy_is_sold(policy))
      this.quotedPolicies = this.policies.filter(policy => (policy.agent_status === 'A' && (policy.quote_status === 'Q'
      && policy.status !== 'D')))
      this.opportunitySold = this.soldPolicies.length
      this.opportunityQuoted = this.quotedPolicies.length
      this.soldPolicies.map(policy => {
          this.totalPremium += parseFloat(policy.total_premium)
          this.basePremium += policy.base_premium ? parseFloat(policy.base_premium) : 0
          this.commission += policy.commission
      })
      const totalPremiumPercent = this.totalPremiumPoliciesGoal ? (parseFloat(this.totalPremium.toFixed(2))
          / this.totalPremiumPoliciesGoal * 100).toFixed(0) : 100
      const commissionPercent = this.commissionPoliciesGoal ? (parseFloat(this.commission.toFixed(2))
          / this.commissionPoliciesGoal * 100).toFixed(0) : 100
      const opportunitiesSoldPercent = this.countPoliciesGoal ? (parseFloat(this.opportunitySold.toFixed(2))
          / this.countPoliciesGoal * 100).toFixed(0) : 100

      this.chartSeriesOportunitySold = [opportunitiesSoldPercent]
      this.chartSeriesTotalPremium = [totalPremiumPercent]
      this.chartSeriesCommission = [commissionPercent]

      this.$set(this.chartOptionsTotalPremium.subtitle, 'text',
          `$ ${number_to_money(this.totalPremium.toFixed(0))} of $ ${this.totalPremiumPoliciesGoal}`)
      this.$set(this.chartOptionsOpportunitySold.subtitle, 'text',
          `${this.opportunitySold} of ${this.countPoliciesGoal}`)
      this.$set(this.chartOptionsCommission.subtitle, 'text',
          `$ ${number_to_money(this.commission)} of ${this.commissionPoliciesGoal}`)

      this.$refs.opSoldChart.updateOptions(this.chartOptionsOpportunitySold, false, true)
      this.$refs.totalPremChart.updateOptions(this.chartOptionsTotalPremium, false, true)
      this.$refs.commissionChart.updateOptions(this.chartOptionsCommission, false, true)

      this.trendsComponent += 1
    },
    getEmployers() {
      axios.get(`${process.env.VUE_APP_API_URL}/employers`, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.employersOptions = response.data.data
      }).catch(error => {
        console.log(error)
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

</style>
