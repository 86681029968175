<template>
<div>
  <b-card>
    <b-card-header>
      <b-card-title class="mb-sm-0 mb-1">
        Metrics
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row class="d-flex justify-content-center" :style="loading ? `opacity: 50%;` : ``">
        <b-spinner v-if="loading" style="width: 7rem; height: 7rem;" class="position-absolute zindex-1 d-flex align-self-center"/>
        <b-col
          cols="12"
          xl="2"
          class="text-center"
        >
          <b-card class="border-primary m-0">
            <animated-number
                class="font-large-2 font-weight-bolder"
                :value="closeRatio"
                :format-value="closeRatioFormatted"
                :duration="1000"
            />
          </b-card>
          <p class="font-large-1 mb-3 mt-1">Close Ratio</p>
        </b-col>
        <b-col
            cols="12"
            xl="2"
            class="text-center mb-1"
        >
          <b-card class="border-primary m-0">
            <animated-number
                class="font-large-2 font-weight-bolder"
                :value="opQuoted"
                :format-value="opQuotedFormatted"
                :duration="1000"
            />
<!--            <p class="font-large-2 font-weight-bolder">{{ opQuoted }}</p>-->
          </b-card>
          <p class="font-large-1 mb-3 mt-1">Opportunities Quoted</p>
        </b-col>
        <b-col
            cols="12"
            xl="2"
            class="text-center mb-1"
        >
          <b-card class="border-primary m-0">
            <animated-number
                class="font-large-2 font-weight-bolder"
                :value="opSold"
                :format-value="opSoldFormatted"
                :duration="1000"
            />
          </b-card>
          <p class="font-large-1 mb-3 mt-1">Opportunities Sold</p>
        </b-col>
        <b-col
            cols="12"
            xl="3"
            class="text-center mb-1"
        >
          <b-card class="border-primary m-0">
            <animated-number
                class="font-large-2 font-weight-bolder"
                :value="basePremium"
                :format-value="formatToPrice"
                :duration="1000"
            />
          </b-card>
          <p class="font-large-1 mb-3 mt-1">Base premium</p>
        </b-col>
        <b-col
            cols="12"
            xl="3"
            class="text-center mb-1"
        >
          <b-card class="border-primary m-0">
            <animated-number
                class="font-large-2 font-weight-bolder"
                :value="totalPremium"
                :format-value="formatToPrice"
                :duration="1000"
            />
          </b-card>
          <p class="font-large-1 mb-3 mt-1">Total premium</p>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</div>
</template>

<script>
import {
  BCard, BCardText, BLink, BRow, BButton, BCardBody, BCardTitle, BCardHeader, BCol, BSkeleton, BSkeletonWrapper,
  BFormCheckbox, BSpinner
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {number_to_money} from "@/_helpers/number_to_money";
import useJwt from '@/auth/jwt/useJwt'
import { analysError } from '@/_helpers/analys_error'
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import TrendsComponent from "@/views/dashboard/TrendsComponent";
import Multiselect from "vue-multiselect";
import AnimatedNumber from 'animated-number-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BButton,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCol,
    BSkeleton,
    BSkeletonWrapper,
    BFormCheckbox,
    BSpinner,
    ToastificationContent,
    AnimatedNumber
  },
  computed: {
    closeRatio() {
      return parseFloat((this.opSold * 100) / this.opQuoted).toFixed(0)
    }
  },
  methods: {
    opSoldFormatted(value) {
      return value.toFixed(0)
    },
    formatToPrice(value) {
      const totalPrem = Intl.NumberFormat('en').format(Number(value).toFixed(0))
      return `$ ${totalPrem}`
    },
    closeRatioFormatted(value) {
      return `${value.toFixed(0)} %`
    },
    opQuotedFormatted(value) {
      return value.toFixed(0)
    },
  },
  props: {
    totalPremium: {
      required: true,
      type: Number
    },
    opSold: {
      required: true,
      type: Number
    },
    opQuoted: {
      required: true,
      type: Number
    },
    basePremium: {
      required: true,
      type: Number
    },
    loading: {
      required: true,
      type: Boolean
    }
  },
}
</script>

<style scoped>

</style>